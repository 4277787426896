﻿@import '../../assets/scss/_master.scss';

global-search {
  #globalSearchContainer {
    @include absolute(top 0 left 0 right 0 bottom 0);
    z-index: 3;
    background-color: rgba($color1, 0.9);
    overflow-y: auto;

    &.selection-mode {
      .body {
        top: 155px;

        .results-container .section .results {
          .item {
            checkbox {
              display: block;
            }

            .button-group {
              display: none;
            }
          }
        }
      }
    }

    .content {
      width: 85%;
      height: 100%;
      min-width: 824px;
      margin: auto;
      font-size: $content1;
      position: relative;
    }

    .top-title {
      font-size: $sub-head2;
      font-weight: bold;
      color: $color8;
      padding-top: 30px;
      height: 55px;
      @include box-sizing(border-box);
    }

    .submit.btn {
      @include absolute(top 30px right 0);
      width: 20px;
      height: 20px;

      svg path {
        fill: $color5;
      }
    }

    .header {
      white-space: nowrap;
      margin: auto;
      height: 100px;
      position: relative;

      #globalSearchBox {
        @include align-vertically();
        left: 0;
        right: 0;
        @include box-sizing(border-box);
        border-radius: 15px;

        .input-wrapper {
          @include absolute(left 150px right 46px);
          z-index: 1;
        }

        $searchInputHeight: 40px; // necessary to do search box this to properly place mag glass icon in IE
        input {
          width: 100%;
          height: $searchInputHeight;
          line-height: $searchInputHeight;
          padding-left: 40px;
          @include border-right-radius(20px);
          background-color: $color8;
          color: $color1;
          outline: none;
          @include box-sizing(border-box);
          font-size: $content2;
        }

        svg {
          height: $searchInputHeight * 0.3;
          width: $searchInputHeight * 0.3;
          @include absolute(top $searchInputHeight * 0.35 left 170px);
          z-index: 2;
        }

        .toggle-container {
          display: inline-block;
          background-color: $rowcolor2;
          @include border-left-radius(20px);
          height: $searchInputHeight;
          line-height: $searchInputHeight;
          padding: 0 10px;
          float: left;
          border-right: 1px solid $color3;

          .label {
            color: $color1;
            margin-left: 7px;
            font-size: $content2;
            vertical-align: initial;
          }

          .toggle {
            box-shadow: none;

            .dot {
              background-color: $rowcolor2;
              @include box-shadow(1px 1px 5px $color3);
            }

            &.active .dot {
              background-color: $color4;
            }
          }
        }
      }

      .close {
        @include align-vertically();
        right: 0;
        width: 20px;
        height: 20px;
        display: inline-block;

        svg path {
          fill: $color5;
        }
      }
    }

    hr {
      border: none;
      border-bottom: 1px solid $color5;
      margin: 0;
      @include absolute(top 27px left 0 right 0);
    }

    .body {
      @include absolute(top 100px left 0 right 0 bottom 0);
      padding-bottom: 60px;
      box-sizing: border-box;

      .tab-container {
        @include absolute(top 0 left 0 right 35px);
        white-space: nowrap;
        overflow: hidden;
        @include animation(fadeIn 200ms ease-out);

        .tab {
          display: inline-block;
          color: $color8;
          padding-bottom: 5px;
          margin-right: 50px;
          @include box-sizing(border-box);

          &:last-child {
            margin: 0;
          }

          &.selected {
            border-bottom: 3px solid $color4;
          }

          &.hide {
            display: none;
          }
        }
      }

      .arrows-container {
        @include absolute(top 2px right -1px); // -1 accounts for white space in SVG
        white-space: nowrap;
        @include animation(fadeIn 200ms ease-out);

        .arrow {
          display: inline-block;
          width: 10px;
          height: 16px;

          &.left {
            @include transform(scale(-1));
          }

          &.right {
            margin-left: 3px;
          }

          &.disabled {
            opacity: 0.3;
          }

          svg path {
            fill: $color8;
          }
        }
      }

      .results-container {
        @include absolute(top 60px left 0 right 0);
        @include animation(fadeIn 200ms ease-out);

        .section {
          width: 100%;
          margin-bottom: 30px;

          .title {
            width: 100%;
            padding: 10px 0;
            @include box-sizing(border-box);
            background-color: $rowcolor2;
            color: $color1;
            border-radius: 10px;
            position: relative;
            margin-bottom: 10px;

            .icon,
            .label {
              display: inline-block;
              vertical-align: middle;
            }

            .icon {
              width: 25px;
              height: 25px;
              margin: 0 17px;

              svg {
                fill: $color1;
              }
            }

            .label {
              font-weight: bold;
            }

            .count {
              font-weight: bold;
              @include align-vertically();
              right: 17px;
            }
          }

          .results {
            width: 100%;

            .item {
              color: $color5;
              display: flex;
              align-items: center;
              height: 30px;
              position: relative;

              &:hover {
                background-color: rgba(255, 255, 255, 0.2);
              }

              checkbox {
                display: none; // only show when in selection-mode
                margin: 0 17px;
              }

              .label {
                width: 20%;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                white-space: nowrap;
                margin-right: 15px;

                &:first-child {
                  padding-left: 17px;
                }

                &.highlight {
                  color: $color4;
                }
              }

              .button-group {
                @include align-vertically();
                right: 0;
                white-space: nowrap;
                text-align: right;
                padding-right: 17px;

                .btn {
                  display: inline-block;
                  margin-left: 17px;

                  &.icon {
                    width: 17px;
                    height: 17px;

                    svg {
                      path,
                      polygon {
                        fill: $color4;
                      }
                    }
                  }
                }
              }

              &.inactive {
                color: $color3;

                .label.highlight {
                  color: $color3;
                }

                .button-group .btn.icon svg {
                  path,
                  polygon,
                  circle {
                    fill: $color3;
                  }
                }
              }
            }
          }
        }
      }

      .friendly-message {
        width: 40%;
        margin: 0 auto;
        text-align: center;
        @include animation(fadeIn 200ms ease-out);

        .icon {
          width: 125px;
          height: 125px;
          margin: auto;

          svg path {
            fill: $color8;
          }
        }

        .text {
          font-weight: bold;
          color: $color8;
        }
      }

      .loading-bars {
        text-align: center;

        div {
          background-color: $color8;
        }
      }
    }
  }
}
